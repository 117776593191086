// src/components/ProfilePhotoUploader.js
import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { storage, firestore } from '../firebase/firebaseConfig';
import './ProfilePhotoUploader.css';

function ProfilePhotoUploader({ userId }) {
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    const fetchPhotoURL = async () => {
      if (userId) {
        const userDoc = await getDoc(doc(firestore, 'users', userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPhotoURL(userData.photoURL || null); // Cargar la foto guardada, si existe
        }
      }
    };
    fetchPhotoURL();
  }, [userId]);

  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const photoRef = ref(storage, `profilePhotos/${userId}`);
      await uploadBytes(photoRef, file);
      const downloadURL = await getDownloadURL(photoRef);

      // Actualizar la URL de la foto en Firestore
      await updateDoc(doc(firestore, 'users', userId), {
        photoURL: downloadURL,
      });

      setPhotoURL(downloadURL); // Actualizar la vista con la nueva foto
    }
  };

  return (
    <div className="photo-card">
      <div className="photo-container">
        {photoURL ? (
          <img src={photoURL} alt="Foto de perfil" className="profile-photo" />
        ) : (
          <div className="placeholder">
            <span role="img" aria-label="placeholder">
              📷
            </span>
          </div>
        )}
      </div>
      <div className="upload-section">
        <label className="upload-button">
          Seleccionar imagen...
          <input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            className="file-input"
          />
        </label>
      </div>
    </div>
  );
}

export default ProfilePhotoUploader;
