import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebarfijo';
import CourseSidebarfijo from '../components/CourseSidebar';
import CourseVideo from '../components/CourseVideo';
import CourseTabs from '../components/CourseTabs';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from '../firebase/firebaseConfig';
import { modules } from '../components/CourseSidebar';
import { useAuthContext } from '../context/authContext';
import './CourseView.css';

function CourseView() {
  const { currentUser } = useAuthContext();
  const [currentLesson, setCurrentLesson] = useState({ moduleIndex: 0, lessonIndex: 0 });
  const [progress, setProgress] = useState({});

  // Cargar progreso desde Firebase al cargar la vista
  useEffect(() => {
    const loadProgress = async () => {
      if (currentUser) {
        const progressRef = doc(firestore, 'progress', currentUser.uid);
        const progressDoc = await getDoc(progressRef);
        if (progressDoc.exists()) {
          const data = progressDoc.data();
          setCurrentLesson(data.currentLesson || { moduleIndex: 0, lessonIndex: 0 });
          setProgress(data.progress || {});
        }
      }
    };
    loadProgress();
  }, [currentUser]);

  // Manejar avance de lección y actualizar progreso en Firebase
  const handleCompleteLesson = async () => {
    const { moduleIndex, lessonIndex } = currentLesson;
    const nextLessonIndex = lessonIndex + 1;
    const newLesson = {
      moduleIndex: moduleIndex + (nextLessonIndex >= modules[moduleIndex].lessons.length ? 1 : 0),
      lessonIndex: nextLessonIndex % modules[moduleIndex].lessons.length,
    };
    setCurrentLesson(newLesson);

    // Actualizar progreso en el estado y en Firebase
    const updatedProgress = {
      ...progress,
      [`${moduleIndex}-${lessonIndex}`]: true,
    };
    setProgress(updatedProgress);

    if (currentUser) {
      const progressRef = doc(firestore, 'progress', currentUser.uid);
      await setDoc(progressRef, { currentLesson: newLesson, progress: updatedProgress }, { merge: true });
    }
  };

  return (
    <div className="course-view-container">
      <Sidebar />
      <div className="main-content">
        <CourseVideo
          lesson={modules[currentLesson.moduleIndex].lessons[currentLesson.lessonIndex]}
          onComplete={handleCompleteLesson}
        />
        <CourseTabs />
      </div>
      <CourseSidebarfijo
        currentLesson={currentLesson}
        onLessonSelect={setCurrentLesson}
        progress={progress}
      />
    </div>
  );
}

export default CourseView;
