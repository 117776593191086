// src/pages/Profile.js

import React from 'react';
import Sidebar from '../components/Sidebar';
import ProfilePhotoUploader from '../components/ProfilePhotoUploader';
import F1ProfileCard from '../components/F1ProfileCard';
import './Profile.css';

function Profile() {
  return (
    <div className="profile-page">
      <Sidebar />
      <div className="profile-main-content">
        <div className="profile-header-container">
          <h2 className="profile-header">Perfil</h2>
          <p className="profile-subheader">Preséntate a la comunidad.</p>
        </div>
        <div className="profile-content">
          <div className="profile-photo-section">
            <ProfilePhotoUploader />
          </div>
          <div className="profile-tabs-section">
            <F1ProfileCard />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
