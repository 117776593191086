import React, { useRef } from 'react';
import './Carrusel.css';

function Carrusel({ modules }) {
  const carruselRef = useRef(null);

  const scrollLeft = () => {
    if (carruselRef.current) {
      carruselRef.current.scrollLeft -= carruselRef.current.offsetWidth;
    }
  };

  const scrollRight = () => {
    if (carruselRef.current) {
      carruselRef.current.scrollLeft += carruselRef.current.offsetWidth;
    }
  };

  return (
    <div className="carrusel-wrapper">
      <button className="carrusel-button left" onClick={scrollLeft}>
        &#9664;
      </button>
      <div className="carrusel-viewport" ref={carruselRef}>
        <div className="carrusel-container">
          {modules.concat(modules).map((module, index) => (
            <div className="carrusel-item" key={index}>
              <img src={module.image} alt={module.title} className="carrusel-image" />
            </div>
          ))}
        </div>
      </div>
      <button className="carrusel-button right" onClick={scrollRight}>
        &#9654;
      </button>
    </div>
  );
}

export default Carrusel;







