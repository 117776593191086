// src/components/F1ProfileCard.js

import React, { useState } from 'react';
import './F1ProfileCard.css';

function F1ProfileCard() {
  const [activeTab, setActiveTab] = useState('Mi Perfil');

  const renderContent = () => {
    switch (activeTab) {
      case 'Mi Perfil':
        return (
          <div className="tab-content">
            <h3>Información Personal</h3>
            <input type="text" placeholder="Nombre" />
            <input type="text" placeholder="Apellido" />
            <input type="text" placeholder="Teléfono" />
            <input type="text" placeholder="País" />
          </div>
        );
      case 'Mi Plan':
        return (
          <div className="tab-content">
            <h3>Detalles de Mi Plan</h3>
            <p>Este es el plan actual del usuario.</p>
          </div>
        );
      case 'Cambiar Contraseña':
        return (
          <div className="tab-content">
            <h3>Cambiar Contraseña</h3>
            <input type="password" placeholder="Contraseña actual" />
            <input type="password" placeholder="Nueva contraseña" />
            <input type="password" placeholder="Confirmar nueva contraseña" />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="f1-profile-card">
      <div className="f1-tabs">
        <span
          className={activeTab === 'Mi Perfil' ? 'f1-tab active' : 'f1-tab'}
          onClick={() => setActiveTab('Mi Perfil')}
        >
          Mi Perfil
        </span>
        <span
          className={activeTab === 'Mi Plan' ? 'f1-tab active' : 'f1-tab'}
          onClick={() => setActiveTab('Mi Plan')}
        >
          Mi Plan
        </span>
        <span
          className={
            activeTab === 'Cambiar Contraseña' ? 'f1-tab active' : 'f1-tab'
          }
          onClick={() => setActiveTab('Cambiar Contraseña')}
        >
          Cambiar Contraseña
        </span>
      </div>
      {renderContent()}
    </div>
  );
}

export default F1ProfileCard;
