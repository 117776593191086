import React, { useState, useEffect } from 'react';
import './RatingStars.css';

function RatingStars({ initialRating = 0, saveRating }) {
  const [rating, setRating] = useState(initialRating);
  const [hoverRating, setHoverRating] = useState(0);

  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (index) => {
    setRating(index);
    if (saveRating) {
      saveRating(index); // Guarda la calificación usando saveRating
    }
  };

  return (
    <div className="rating-stars-container" title="Avaliar aula">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          className={`star ${index <= (hoverRating || rating) ? 'filled' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          ★
        </span>
      ))}
      <div className="rating-text">{rating}/5</div>
    </div>
  );
}

export default RatingStars;
