import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import HeaderDashboard from '../components/HeaderDashboard';
import Carrusel from '../components/Carrusel';
import bannerImage from '../assets/images/bannerarriba.png';
import modulo1Image from '../assets/images/Modulo1.jpg';
import modulo2Image from '../assets/images/Modulo2.jpg';
import modulo3Image from '../assets/images/Modulo3.jpg';
import modulo4Image from '../assets/images/Modulo4.jpg';
import modulo5Image from '../assets/images/Modulo5.jpg';
import modulo6Image from '../assets/images/Modulo6.jpg';
import modulo7Image from '../assets/images/Modulo7.jpg';
import modulo9Image from '../assets/images/Modulo9.jpg';
import bonus1Image from '../assets/images/Bonus1.jpg';
import bonus2Image from '../assets/images/Bonus2.jpg';
import './Dashboard.css';
import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../firebase/firebaseConfig';
import { useAuthContext } from '../context/authContext';

const carouselModules = [
  { title: 'Módulo 1: Bienvenidos al mercado', description: 'Conceptos básicos del curso', image: modulo1Image },
  { title: 'Módulo 2: Embudos de venta', description: 'Profundización en estrategias de tráfico', image: modulo2Image },
  { title: 'Módulo 3: Copywriting', description: 'Aplicación práctica de los conocimientos', image: modulo3Image },
  { title: 'Módulo 4: Prospección y prestación de servicios de gestión de tráfico pago', description: 'Técnicas avanzadas y casos de estudio', image: modulo4Image },
  { title: 'Módulo 5: FACEBOOK Ads', description: 'Optimización para mejorar el rendimiento', image: modulo5Image },
  { title: 'Módulo 6: Google Ads', description: 'Explora estrategias de Google Ads', image: modulo6Image },
  { title: 'Módulo 7: TikTok Ads', description: 'Implementación de campañas en TikTok', image: modulo7Image },
  { title: 'Módulo 9: Automatización de WhatsApp', description: 'Automatización de mensajes en WhatsApp', image: modulo9Image },
  { title: 'Bonus 1: Edición con CapCut', description: 'Edita y optimiza contenido con CapCut', image: bonus1Image },
  { title: 'Bonus 2: Programación de publicaciones', description: 'Automatiza tus publicaciones', image: bonus2Image },
];

function Dashboard() {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [nextModuleTitle, setNextModuleTitle] = useState('');
  const [nextLessonTitle, setNextLessonTitle] = useState('');

  useEffect(() => {
    const loadProgressData = async () => {
      if (currentUser) {
        const progressRef = doc(firestore, 'progress', currentUser.uid);
        const progressDoc = await getDoc(progressRef);

        if (progressDoc.exists()) {
          const data = progressDoc.data();
          const completedLessons = Object.values(data.progress || {}).filter(Boolean).length;
          const totalLessons = carouselModules.length;
          setProgressPercentage(Math.round((completedLessons / totalLessons) * 10));

          const { moduleIndex = 0, lessonIndex = 0 } = data.currentLesson || {};
          const currentModule = carouselModules[moduleIndex];
          const nextLesson = currentModule ? `Lección ${lessonIndex + 1}` : 'No hay lección disponible';
          setNextModuleTitle(currentModule ? currentModule.title : 'No hay módulo disponible');
          setNextLessonTitle(nextLesson);
        }
      }
    };
    loadProgressData();
  }, [currentUser]);

  const handleIrAlCurso = () => {
    navigate('/CourseView');
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <HeaderDashboard userEmail={currentUser?.email || 'usuario@correo.com'} />

        <div className="banner-container">
          <img src={bannerImage} alt="Cursos Virtuales" className="banner-image" />
        </div>

        <div className="dashboard-saludo">
          <div className="saludo-text">
            <h2>¡Hola, {currentUser?.email || 'usuario@correo.com'}!</h2>
          </div>
          
          <div className="estado-curso-container">
            <div className="estado-curso-text">
              <span>Estado de la cursada</span>
              <div className="curso-estado">
                <span className="curso-dropdown">Cursando</span>
                <span className="curso-check">✔️</span>
              </div>
            </div>
          </div>
        </div>

        <div className="curso-progreso-container">
          <div
            className="curso-progreso-circulo"
            style={{ "--progress-percentage": progressPercentage }}
            data-progress={`${progressPercentage}%`}
          >
            <span className="curso-progreso-text">Cursando</span>
          </div>

          <div className="curso-informacion">
            <div className="curso-titulo">
              <h3>BOOTCAMP DE TRÁFICO PAGO</h3>
              <span className="curso-modalidad">100% On Demand</span>
            </div>

            <div className="curso-detalles">
              <p>📘 Comisión 58970</p>
              <p>📅 Curso de 4 semanas</p>
              <p>📅 Libre</p>
              <p>⏰ Disponible 24/7</p>
            </div>
          </div>

          <div className="curso-estado-final">
            <p>📆 Estás al día con tu cursada</p>
            <p>Estás cursando:</p>
            <p className="Topetitud">{nextModuleTitle} - {nextLessonTitle}</p>
            <button onClick={handleIrAlCurso} className="ir-curso-boton">Ir al curso</button>
          </div>
        </div>

        <h2 className="dashboard-section-title">Contenido del curso</h2>
        <Carrusel modules={carouselModules} />
      </div>
    </div>
  );
}

export default Dashboard;
