// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import CourseView from './pages/CourseView';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/authContext';
import { CourseProgressProvider } from './context/CourseProgressContext';
import Register from './pages/Register';
import Profile from './pages/Profile';


function App() {
  return (
    <AuthProvider> {/* AuthProvider envolviendo todo */}
      <CourseProgressProvider>
        <Router> {/* Router dentro de los Providers */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/CourseView"
              element={
                <ProtectedRoute>
                  <CourseView />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </CourseProgressProvider>
    </AuthProvider>
  );
}

export default App;

