import React, { useState } from 'react';
import './CourseTabs.css';
import CommentSection from './CommentSection'; // Importa el nuevo componente de comentarios

const CourseTabs = () => {
  const [activeTab, setActiveTab] = useState("description");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="course-tabs">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "description" ? "active" : ""}`}
          onClick={() => handleTabClick("description")}
        >
          Descripción
        </button>
        <button
          className={`tab-button ${activeTab === "comments" ? "active" : ""}`}
          onClick={() => handleTabClick("comments")}
        >
          Comentarios
        </button>
        <button
          className={`tab-button ${activeTab === "annexes" ? "active" : ""}`}
          onClick={() => handleTabClick("annexes")}
        >
          Anexos
        </button>
      </div>

      <div className="tab-content">
        {activeTab === "description" && (
          <div className="tab-panel">
            <h3>Descripción del Curso</h3>
            <p>Aquí irá la descripción detallada del curso.</p>
          </div>
        )}
        {activeTab === "comments" && (
          <div className="tab-panel">
            <CommentSection /> {/* Muestra la sección de comentarios */}
          </div>
        )}
        {activeTab === "annexes" && (
          <div className="tab-panel">
            <h3>Anexos</h3>
            <p>Aquí se mostrarán documentos o materiales adicionales.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseTabs;


