// HeaderDashboard.js
import React, { useState, useEffect, useRef } from 'react';
import './HeaderDashboard.css';

function HeaderDashboard({ userEmail }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const getInitial = () => (userEmail ? userEmail.charAt(0).toUpperCase() : '');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="header-dashboard">
      <h2 className="header-title">Inicio</h2>
      <div className="user-menu" ref={dropdownRef}>
        <div className="user-icon" onClick={toggleDropdown}>
          {getInitial()}
        </div>
        {isOpen && (
          <div className="dropdown-menu">
            <button className="dropdown-item">Configurar perfil</button>
            <button className="dropdown-item">Cerrar sesión</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderDashboard;






