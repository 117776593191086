import React, { useState } from 'react';
import './Comment.css';

const Comment = ({ comment, onReply }) => {
  const [reply, setReply] = useState('');
  const [showReplyBox, setShowReplyBox] = useState(false);

  const handleReplySubmit = () => {
    if (reply.trim()) {
      onReply(reply);
      setReply('');
      setShowReplyBox(false);
    }
  };

  return (
    <div className="comment">
      <p className="comment-text">{comment.text}</p>
      <button onClick={() => setShowReplyBox(!showReplyBox)}>
        Responder
      </button>
      {showReplyBox && (
        <div className="reply-box">
          <textarea
            placeholder="Escribe una respuesta..."
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          ></textarea>
          <button onClick={handleReplySubmit}>Enviar respuesta</button>
        </div>
      )}
      {comment.replies.length > 0 && (
        <div className="replies">
          {comment.replies.map((reply, index) => (
            <p key={index} className="reply-text">
              {reply}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Comment;
