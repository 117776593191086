// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuthContext();

  if (!currentUser) {
    // Si no está autenticado, redirige al Login
    return <Navigate to="/login" replace />;
  }

  // Si está autenticado, muestra el componente hijo (Dashboard u otros)
  return children;
};

export default ProtectedRoute;
