import React from 'react';
import Module from './Module';
import './CourseSidebar.css';

// Definimos los módulos del curso
const modules = [
  {
    title: "1- Bienvenidos al mercado",
    lessons: [
      "El maldito marketing digital",
      "Cómo puedo hacer dinero en este mercado?",
      "Estrategias de ventas",
      "¿Qué es un embudo de ventas?",
      "Tipos de marketing",
      "Niveles de conciencia",
      "Escalera de valor",
      "Procesos de tráfico directo",
      "Paso a paso para los 10k",
    ],
  },
  {
    title: "2- Embudos de venta",
    lessons: [
      "Embudo de VSL (Tráfico Directo)",
      "Embudo de WhatsApp (Tráfico Directo)",
      "Embudo de Webinar",
      "Embudo de Lanzamiento Interno",
      "Embudo Tripwire",
      "Embudo de Lanzamiento Meteórico",
      "Embudo VIP",
    ],
  },
  {
    title: "3- Copywriting",
    lessons: [
      "¿Qué es el copy y cómo aplicarlo?",
      "Gatillos mentales",
      "Oportunidades y pruebas",
      "Quiebra de objeciones",
      "Arquetipos de clientes",
    ],
  },
  {
    title: "4- Prospección y prestación de servicio de gestión de tráfico pago",
    lessons: [
      "Democratización de la publicidad",
      "Entendiendo el mercado de actuación",
      "Definiendo entregables",
      "Precificación de servicios",
      "A quién le voy a vender mis servicios? Aptitud",
      "Cómo atraer clientes interesados en nuestro servicio",
      "Cerrando clientes con propuestas irresistibles",
      "Cómo facturar por lo menos 1500 dólares en los próximos 90 días prestando servicios de tráfico pago",
    ],
  },
  {
    title: "5- Facebook Ads",
    lessons: [
      "Terminología que necesitas saber sobre tráfico pago",
      "Creando el Business Manager y la cuenta publicitaria",
      "Creación y configuración de Fanpage",
      "Conectando el Instagram a la Fanpage",
      "Conociendo el administrador de anuncios por dentro",
      "Calentando la cuenta de anuncios",
      "Diferentes objetivos de campañas",
      "Píxel - ¿Qué es y cómo funciona?",
      "Tipos de públicos y segmentaciones",
      "Catálogo de audiencias",
      "Creación de públicos personalizados",
      "Creación de públicos semejantes",
      "Creación de públicos de intereses",
      "Nomenclatura de campañas",
      "Tipos de presupuestos",
      "Jerarquía y exclusión de públicos",
      "Creando tu primera campaña",
      "Posicionamiento de campañas",
      "Configurando las métricas en el panel",
      "Análisis de las métricas",
      "Optimizando las campañas",
      "Campañas de remarketing",
      "Campañas de video view y contenido",
      "Campañas para ganar seguidores en Instagram",
      "Escala de campañas",
      "Finalización de módulo",
    ],
  },
  {
    title: "6- Google Ads",
    lessons: ["Lección 1", "Lección 2", "Lección 3", "Lección 4", "Lección 5"],
  },
  {
    title: "7- TikTok Ads",
    lessons: ["Lección 1", "Lección 2", "Lección 3", "Lección 4", "Lección 5"],
  },
  {
    title: "8- Email Marketing",
    lessons: ["Lección 1", "Lección 2", "Lección 3", "Lección 4", "Lección 5"],
  },
  {
    title: "9- Automatización de WhatsApp",
    lessons: ["Lección 1", "Lección 2", "Lección 3", "Lección 4", "Lección 5"],
  },
  {
    title: "10- Herramientas",
    lessons: ["Lección 1", "Lección 2", "Lección 3", "Lección 4", "Lección 5"],
  },
  {
    title: "Bonus 1- Edición con CapCut",
    lessons: [],
  },
  {
    title: "Bonus 2- Programación de publicaciones con Meta Business Suite",
    lessons: [],
  },
  {
    title: "Bonus 3- Ser Freelancer",
    lessons: [],
  },
];

const CourseSidebar = ({ currentLesson, onLessonSelect, progress }) => {
  // Función para contar las lecciones completadas
  const countCompletedLessons = () => {
    return Object.values(progress).filter(Boolean).length;
  };

  return (
    <div className="course-sidebar">
      <h3>Progreso del Curso</h3>
      <p>{countCompletedLessons()}/{modules.reduce((total, mod) => total + mod.lessons.length, 0)} completadas</p>
      {modules.map((module, moduleIndex) => (
        <Module
          key={moduleIndex}
          moduleNumber={moduleIndex + 1}
          moduleTitle={module.title}
          lessons={module.lessons}
          progress={progress}
          currentLesson={currentLesson}
          onLessonSelect={onLessonSelect}
        />
      ))}
    </div>
  );
};

export default CourseSidebar;
export { modules };
