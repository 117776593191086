// src/pages/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import './Register.css';
import logo from '../assets/images/logo-academia.png';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar la contraseña
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    try {
      // Crear usuario con Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Guardar información adicional en Firestore
      await setDoc(doc(firestore, 'users', user.uid), {
        firstName,
        lastName,
        country,
        email,
        createdAt: new Date(),
      });

      alert('Usuario registrado con éxito');
      navigate('/dashboard'); // Redirigir al dashboard tras el registro exitoso
    } catch (err) {
      setError('Error al crear la cuenta. Intenta nuevamente.');
      console.error(err);
    }
  };

  return (
    <div className="register-page">
      <img src={logo} alt="Logo" className="register-logo" />
      <div className="register-container">
        <h2 className="register-title">REGISTRARSE</h2>
        <div className="line-separator"></div>
        <form className="register-form" onSubmit={handleRegister}>
          <div className="input-group">
            <input
              type="text"
              className="register-input"
              placeholder="Nombre"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              className="register-input"
              placeholder="Apellido"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              className="register-input"
              placeholder="País"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              className="register-input"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type={showPassword ? 'text' : 'password'} // Cambia el tipo de input para mostrar/ocultar contraseña
              className="register-input"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type={showPassword ? 'text' : 'password'}
              className="register-input"
              placeholder="Confirmar contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="options">
            <label>
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />{' '}
              Mostrar contraseña
            </label>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="register-button">
            Crear Cuenta
          </button>
        </form>
        <p className="register-footer">
          Al registrarse, acepta nuestras políticas. Lea los{' '}
          <a href="/terms" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>.
        </p>
      </div>
      <p className="powered-by">Powered by Bond</p>
    </div>
  );
}

export default Register;
