import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaComments, FaRoute, FaBookOpen, FaUsers, FaBell, FaCalendarAlt, FaBriefcase, FaSignOutAlt } from 'react-icons/fa';
import logo from '../assets/images/logo-academia.png';
import './Sidebarfijo.css';
import { useAuthContext } from '../context/authContext';

function Sidebar() {
  const { logout } = useAuthContext();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  return (
    <div className="sidebarfijo">
      <div className="sidebarfijo-header">
        <img src={logo} alt="Logo" className="sidebarfijo-logo" />
      </div>
      <nav className="sidebarfijo-nav">
        <Link to="/dashboard" className="sidebarfijo-link">
          <FaHome className="icon" /> Inicio
        </Link>
        <Link to="/chat" className="sidebarfijo-link">
          <FaComments className="icon" /> Chat
        </Link>
        <Link to="/ruta" className="sidebarfijo-link">
          <FaRoute className="icon" /> Mi ruta
        </Link>
        <hr className="sidebarfijo-divider" />
        <Link to="/cursos" className="sidebarfijo-link">
          <FaBookOpen className="icon" /> Más cursos <span className="soon-badge">Muy Pronto</span>
        </Link>
        <Link to="/academyjobs" className="sidebarfijo-link">
          <FaBriefcase className="icon" /> AcademyJobs
        </Link>
        <hr className="sidebarfijo-divider" />
        <Link to="/comunidad" className="sidebarfijo-link">
          <FaUsers className="icon" /> Comunidad
        </Link>
        <Link to="/notificaciones" className="sidebarfijo-link">
          <FaBell className="icon" /> Notificaciones
        </Link>
        <Link to="/eventos" className="sidebarfijo-link">
          <FaCalendarAlt className="icon" /> Eventos
        </Link>
      </nav>
      <div className="sidebarfijo-footer">
        <button onClick={handleLogout} className="sidebarfijo-link logout-button">
          <FaSignOutAlt className="icon" /> Cerrar sesión
        </button>
      </div>
    </div>
  );
}

export default Sidebar;


