import React, { useState, useEffect } from 'react';
import RatingStars from './RatingStars';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from '../firebase/firebaseConfig';
import { useAuthContext } from '../context/authContext';
import './CourseVideo.css';

function CourseVideo({ lesson, onComplete }) {
  const { currentUser } = useAuthContext();
  const [rating, setRating] = useState(0);

  // Cargar la calificación guardada al cargar el video
  useEffect(() => {
    const loadRating = async () => {
      if (currentUser && lesson) {
        const ratingRef = doc(firestore, 'ratings', `${currentUser.uid}_${lesson}`);
        const ratingDoc = await getDoc(ratingRef);
        if (ratingDoc.exists()) {
          setRating(ratingDoc.data().rating || 0);
        }
      }
    };
    loadRating();
  }, [currentUser, lesson]);

  // Guardar la calificación en la base de datos
  const handleRatingChange = async (newRating) => {
    setRating(newRating);
    if (currentUser && lesson) {
      const ratingRef = doc(firestore, 'ratings', `${currentUser.uid}_${lesson}`);
      await setDoc(ratingRef, { rating: newRating }, { merge: true });
    }
  };

  return (
    <div className="course-video-container">
      <div className="video-frame">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="Video tutorial"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="video-info">
        <h2 className="video-title">Título del Video: {lesson}</h2>
        <div className="video-actions">
          <RatingStars initialRating={rating} saveRating={handleRatingChange} />
          <button className="advance-button" onClick={onComplete}>Concluir y avanzar</button>
        </div>
      </div>
    </div>
  );
}

export default CourseVideo;

