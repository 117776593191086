import React, { useState } from 'react';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from '../assets/images/logo-academia.png'; // Asegúrate de tener el logo en la ruta correcta
import { FaEnvelope, FaLock } from 'react-icons/fa';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (err) {
      setError('Error de inicio de sesión. Por favor, revisa tus credenciales.');
    }
  };

  const handleCreatePassword = () => {
    alert('Funcionalidad para crear contraseña.');
  };

  return (
    <div className="veron-page">
      <img src={logo} alt="Logo" className="veron-logo" />

      <div className="andujar-container">
        <h2 className="boselli-title">INICIAR SESIÓN</h2>
        <div className="chapu-separator"></div>
        
        <form onSubmit={handleLogin} className="zielinski-form">
          <div className="carrillo-group">
            <label className="sosa-label">
              <FaEnvelope className="re-label" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="corcho-input"
                placeholder="Correo electrónico"
              />
            </label>
          </div>
          
          <div className="carrillo-group">
            <label className="sosa-label">
              <FaLock className="re-label" />
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="corcho-input"
                placeholder="Contraseña"
              />
            </label>
          </div>
          
          <div className="rojo-options">
            <label className="rodriguez-password">
              <input
                type="checkbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              Mostrar contraseña
            </label>
            <a href="/forgot-password" className="guzman-password">Olvidé mi contraseña</a>
          </div>

          {error && <div className="benitez-message">{error}</div>}
          <button type="submit" className="benedetti-button">Entrar</button>
          
          <div className="rulli-protection">
            <i className="fa fa-lock"></i> Sus datos están protegidos.
          </div>
        </form>
      </div>

      <div className="pellegrini-container">
        <h2 className="calderon-title">¿YA SE HA REGISTRADO?</h2>
        <p className="morel-text">
          Si ya se ha registrado y no ha recibido sus datos de acceso por correo electrónico,
          haga clic en el botón siguiente para crear su primera contraseña:
        </p>
        <button onClick={handleCreatePassword} className="desabato-button">Crear Contraseña</button>
      </div>

      <footer className="milito-footer">
        No compartimos su información. Sus datos están seguros y no se compartirán con terceros.
        <br />
        <a href="/terms" className="alayes-link">Términos de Uso</a> y <a href="/privacy" className="alayes-link">Política de Privacidad</a>.
      </footer>
      <div className="sarulyte-powered">
        Powered by Bond
      </div>
    </div>
  );
}

export default Login;
