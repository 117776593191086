import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Importar almacenamiento de Firebase

const firebaseConfig = {
  apiKey: "AIzaSyCXRzRTqikkZm9opFA_5B2OrDv-OunP68o",
  authDomain: "academiaonline-2d993.firebaseapp.com",
  projectId: "academiaonline-2d993",
  storageBucket: "academiaonline-2d993.appspot.com",
  messagingSenderId: "514676327959",
  appId: "1:514676327959:web:e6f770546ce7a4fa117e52",
  measurementId: "G-D8C1YWXP5K"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Inicializar la autenticación
const auth = getAuth(app);

// Configurar persistencia de sesión para que se mantenga al recargar
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

// Inicializar Firestore y Storage
const firestore = getFirestore(app);
const storage = getStorage(app); // Inicializar el almacenamiento

export { auth, firestore, storage }; // Exportar el almacenamiento también





