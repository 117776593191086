import React, { useState } from 'react';
import Comment from './Comment';
import './CommentSection.css';

const CommentSection = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const handleAddComment = () => {
    if (newComment.trim()) {
      setComments([...comments, { text: newComment, replies: [] }]);
      setNewComment('');
    }
  };

  const handleReply = (index, replyText) => {
    const updatedComments = [...comments];
    updatedComments[index].replies.push(replyText);
    setComments(updatedComments);
  };

  return (
    <div className="comment-section">
      <h3>Comentarios ({comments.length})</h3>
      <div className="new-comment">
        <textarea
          placeholder="Escribe un comentario..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        ></textarea>
        <button onClick={handleAddComment}>Enviar comentario</button>
      </div>
      <div className="comments-list">
        {comments.map((comment, index) => (
          <Comment
            key={index}
            comment={comment}
            onReply={(replyText) => handleReply(index, replyText)}
          />
        ))}
      </div>
    </div>
  );
};

export default CommentSection;
