// Importación de React y dependencias necesarias
import React, { useState } from 'react'; // Asegúrate de importar useState
import { Link } from 'react-router-dom';
import './Home.css'; // Mantener los estilos CSS
import "slick-carousel/slick/slick.css";  
import "slick-carousel/slick/slick-theme.css";

// Imágenes y logos
import logo from '../assets/images/logo-academia.png'; 
import primeraFoto from '../assets/images/primera-foto.png';

// Imágenes de la Sección 4
import modulo1 from '../assets/images/Modulo1.jpg';
import modulo2 from '../assets/images/Modulo2.jpg';
import modulo3 from '../assets/images/Modulo3.jpg';
import modulo4 from '../assets/images/Modulo4.jpg';
import modulo5 from '../assets/images/Modulo5.jpg';
import modulo6 from '../assets/images/Modulo6.jpg';
import modulo7 from '../assets/images/Modulo7.jpg';
import modulo9 from '../assets/images/Modulo9.jpg';
import bonus1 from '../assets/images/Bonus1.jpg';
import bonus2 from '../assets/images/Bonus2.jpg';

// Imágenes de la Sección 5 (Testimonios)
import thiago from '../assets/images/thiago.png';
import vanessa from '../assets/images/vanessa.png';
import artur from '../assets/images/artur.png';
import bruna from '../assets/images/bruna.png';
import clara from '../assets/images/clara.png';
import eliana from '../assets/images/eliana.png';
import evelin from '../assets/images/evelin.png';
import marcos from '../assets/images/marcos.png';
// Imágenes de la Sección 6 (experto)
import gabriel from '../assets/images/gabrielfin2.png';

// Carrusel de testimonios
import Slider from "react-slick";

// Componente de Pregunta Frecuente (FAQ)
function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => setIsOpen(!isOpen);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={toggleFAQ}>
      <div className="faq-question">
        <h3>{question}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="faq-answer"><p>{answer}</p></div>}
    </div>
  );
}

// Componente de Testimonio (Sección 5)
function Testimonial({ img, name, role, text }) {
  return (
    <div className="testimonial-box">
      <p>{text}</p>
      <div className="testimonial-footer">
        <img src={img} alt={name} className="testimonial-img" />
        <div>
          <h4>{name}</h4>
          <p>{role}</p>
        </div>
        <div className="stars">★★★★★</div>
      </div>
    </div>
  );
}

// Componente Principal Home
function Home() {
  // Configuración de los carruseles
  const settingsRight = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    rtl: false,
  };

  const settingsLeft = {
    ...settingsRight,
    rtl: true,
  };

  const testimonials = [
    { img: evelin, name: "Evelin Matos", role: "Diseñadora", text: "Desde que me uní a Traffiker Academy, mi forma de trabajar cambió por completo..." },
    { img: thiago, name: "Thiago Cloves", role: "Traffiker", text: "Transformó mi trabajo en marketing digital, logrando resultados precisos y rápidos." },
    { img: vanessa, name: "Vanessa Lopes", role: "Community Manager", text: "Traffiker Academy ha sido lo que necesitaba para avanzar..." },
    { img: artur, name: "Artur Oliveira", role: "Experto en Google Ads", text: "Siempre busco herramientas que mejoren mi día a día." },
    { img: bruna, name: "Bruna Braga", role: "Traffiker", text: "Las estrategias avanzadas y el soporte fueron cruciales." },
    { img: clara, name: "Clara Santos", role: "Diseñadora", text: "Ha sido un camino de transformación increíble." },
    { img: eliana, name: "Eliana Silva", role: "Diseñadora", text: "Me permitió mejorar rápidamente mis habilidades." },
    { img: marcos, name: "Marcos Santos", role: "Diseñador", text: "Las estrategias de esta academia llevaron mi carrera a otro nivel." }
  ];

  const faqs = [
    {
      question: "¿Qué es Traffiker Academy y cómo funciona?",
      answer: "Es una plataforma que ofrece cursos avanzados para optimizar estrategias de marketing digital."
    },
    {
      question: "¿Es necesario pagar para utilizar la plataforma?",
      answer: "Sí, ofrecemos planes accesibles con soporte completo y acceso a contenido exclusivo."
    },
    {
      question: "¿Existe soporte al cliente si tengo dudas o problemas?",
      answer: "Contamos con un equipo de soporte listo para ayudarte en cualquier momento."
    },
    {
      question: "¿Traffiker Academy se actualiza regularmente con nuevos recursos?",
      answer: "Sí, mantenemos nuestros cursos y recursos al día para ofrecerte lo mejor."
    },
    {
      question: "¿Cuáles son los beneficios de ser un usuario premium?",
      answer: "Acceso a contenido exclusivo, soporte prioritario y recursos avanzados para mejorar tus habilidades."
    },
  ];

  return (
    <div className="home">
      {/* Header */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo Academia" className="logo" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
            <li className="nav-item"><Link to="/academy" className="nav-link">Academy</Link></li>
            <li className="nav-item"><Link to="/planes" className="nav-link">Planes</Link></li>
            <li className="nav-item"><Link to="/faq" className="nav-link">FAQ</Link></li>
          </ul>
          <Link to="/login" className="btn btn-primary ms-3">Unirme Ahora</Link>
        </div>
      </nav>

      {/* Sección Hero */}
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1>
                <span className="text-white">Cambia la forma de</span> <br />
                <span className="highlight">vender con Estrategias que si funcionan</span>
              </h1>
              <p>
                Aprende las estrategias que funcionan, comparte con otros y recibe el 
                apoyo que necesitas para llevar tu negocio al siguiente nivel.
              </p>
              <div className="hero-buttons mt-4">
                <button className="btn btn-primary me-3">Unirme ahora</button>
                <button className="btn btn-outline-light">Hablar con ventas</button>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img src={primeraFoto} alt="Estrategias" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </section>

      {/* Sección 2: Video */}
      <section className="video-section">
        <div className="video-adorno"></div>
        <div className="container video-container">
          <div className="video-box">
            <iframe
              src="https://www.youtube.com/embed/YOUTUBE_VIDEO_ID?rel=0&controls=0&showinfo=0&modestbranding=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      {/* Sección 3: Características */}
      <section className="section-three">
        <div className="container">
          <div className="row text-center mb-5">
            <div className="col-md-4">
              <div className="feature-box">
                <img src={require('../assets/images/ig.png')} alt="Redes sociales" className="feature-icon" />
                <h3>Redes sociales</h3>
                <p>Domina las plataformas para crear campañas efectivas y atraer más clientes.</p>
                <button className="btn btn-link">Sumarme ahora →</button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-box">
                <img src={require('../assets/images/estra.png')} alt="Estrategia" className="feature-icon" />
                <h3>Estrategia</h3>
                <p>Diseña estrategias ganadoras desde cero hasta la conversión.</p>
                <button className="btn btn-link">Sumarme ahora →</button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature-box">
                <img src={require('../assets/images/sopor.png')} alt="Soporte continuo" className="feature-icon" />
                <h3>Soporte continuo</h3>
                <p>Soporte constante para ajustar tu estrategia en tiempo real.</p>
                <button className="btn btn-link">Sumarme ahora →</button>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={require('../assets/images/Logo-grande.png')} alt="Logo Grande" className="large-logo" />
            </div>
            <div className="col-md-6 text-white">
              <h2>Tráfico de pago <span className="highlight">de cero a avanzado</span></h2>
              <p>Únete hoy a nuestra comunidad única en el tráfico de pago.</p>
              <button className="btn btn-outline-light">Saber Más</button>
            </div>
          </div>
        </div>
      </section>

      {/* Sección 4: Imágenes fijas */}
      <section className="image-grid-section">
        <div className="container text-center">
          <h2 className="section-title">Beneficios de unirte a Traffiker Academy</h2>
          <p className="section-subtitle">Formación constante con cursos actualizados.</p>
          <div className="row">
            <div className="col-md-2">
              <img src={modulo1} alt="Modulo 1" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={modulo2} alt="Modulo 2" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={modulo3} alt="Modulo 3" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={modulo4} alt="Modulo 4" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={modulo5} alt="Modulo 5" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={modulo6} alt="Modulo 6" className="grid-image" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-2">
              <img src={modulo7} alt="Modulo 7" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={modulo9} alt="Modulo 9" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={bonus1} alt="Bonus 1" className="grid-image" />
            </div>
            <div className="col-md-2">
              <img src={bonus2} alt="Bonus 2" className="grid-image" />
            </div>
          </div>
          <div className="mt-5">
            <button className="btn btn-primary btn-lg">Unirme ahora</button>
          </div>
        </div>
      </section>
      {/* Sección 5: Testimonios */}
      <section className="testimonials-section">
  <h2 className="section-title">¿Qué dicen los estudiantes de la comunidad?</h2>
  <p className="section-subtitle">Lea los agradecimientos de nuestros alumnos</p>

  <div className="testimonials-container">
    {/* Fila de testimonios que se mueve hacia la derecha */}
    <div className="slider-row slider-right">
      {[...Array(5)].map((_, i) => (
        <>
          <div className="testimonial-item" key={`right-${i}-1`}>
            <p>Desde que me uní a Traffiker Academy, mi forma de trabajar con campañas publicitarias cambió por completo.</p>
            <img src={evelin} alt="Evelin Matos" />
          </div>
          <div className="testimonial-item" key={`right-${i}-2`}>
            <p>Transformó mi trabajo en marketing digital. Las estrategias que aprendí son mis mejores aliadas.</p>
            <img src={thiago} alt="Thiago Cloves" />
          </div>
          <div className="testimonial-item" key={`right-${i}-3`}>
            <p>Traffiker Academy ha sido lo que necesitaba para avanzar en mi carrera y mejorar mis habilidades.</p>
            <img src={vanessa} alt="Vanessa Lopes" />
          </div>
          <div className="testimonial-item" key={`right-${i}-4`}>
            <p>El soporte constante me permitió crecer rápidamente en mi trabajo con campañas pagadas.</p>
            <img src={artur} alt="Artur Oliveira" />
          </div>
        </>
      ))}
    </div>

    {/* Fila de testimonios que se mueve hacia la izquierda */}
    <div className="slider-row slider-left">
      {[...Array(5)].map((_, i) => (
        <>
          <div className="testimonial-item" key={`left-${i}-1`}>
            <p>Mi camino en el marketing digital dio un giro gigante gracias al soporte constante.</p>
            <img src={bruna} alt="Bruna Braga" />
          </div>
          <div className="testimonial-item" key={`left-${i}-2`}>
            <p>Ha sido un camino de transformación increíble. Las herramientas obtenidas fueron clave.</p>
            <img src={clara} alt="Clara Santos" />
          </div>
          <div className="testimonial-item" key={`left-${i}-3`}>
            <p>Me permitió mejorar rápidamente en la creación de campañas publicitarias y estrategias.</p>
            <img src={eliana} alt="Eliana Silva" />
          </div>
          <div className="testimonial-item" key={`left-${i}-4`}>
            <p>Las estrategias avanzadas de Traffiker Academy me ayudaron a duplicar mis resultados.</p>
            <img src={marcos} alt="Marcos Santos" />
          </div>
        </>
      ))}
    </div>
  </div>
</section>
{/* Sección de FAQs */}
<section className="faq-section">
  <div className="faq-left">
    <h2>Preguntas Frecuentes</h2>
    <p>Algunas de las preguntas que más hacen antes de sumarse a la academia</p>
    <button>Hablar con ventas</button>
  </div>

  <div className="faq-right">
    {faqs.map((faq, index) => (
      <FAQItem key={index} question={faq.question} answer={faq.answer} />
    ))}
  </div>
</section>

{/* Sección de experto */}

<section className="specialist-section">
  <div className="specialist-container">
    <div className="specialist-image">
      <img src={gabriel} alt="Gabriel Ávalos" />
    </div>
    <div className="specialist-content">
      <h2>Conoce al Especialista:</h2>
      <p>
        Gabriel Ávalos es un joven que, en poco tiempo, se convirtió en un experto en estrategias digitales. 
        Ha llevado su carrera en marketing digital a otro nivel, impulsando negocios con resultados extraordinarios.
      </p>
      <p>
        Siguiendo un modelo basado en la optimización de campañas y el análisis detallado, Gabriel se ha posicionado 
        como una referencia en su ciudad y a nivel internacional cuando se trata de mejorar el rendimiento de anuncios online.
      </p>
      <button className="specialist-button">Unirme ahora</button>
    </div>
  </div>
</section>

{/* Footer*/}
<footer className="footer">
  <div className="footer-container">
    {/* Logo a la izquierda */}
    <div className="footer-logo">
      <img src={logo} alt="Logo Academia" />
    </div>

    {/* Mapa del Sitio en el Centro */}
    <div className="footer-site-map">
      <h4>Mapa del Sitio</h4>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/academy">Academy</Link></li>
        <li><Link to="/planes">Planes</Link></li>
        <li><Link to="/faq">FAQ</Link></li>
        <li><Link to="/login">Unirme Ahora</Link></li>
      </ul>
    </div>

    {/* Links importantes a la derecha */}
    <div className="footer-legal">
      <h4>Links Importantes</h4>
      <ul>
        <li><Link to="/pago">Página de Pago</Link></li>
        <li><Link to="/privacidad">Políticas de Privacidad</Link></li>
        <li><Link to="/terminos">Términos de Uso</Link></li>
      </ul>
    </div>
  </div>

  {/* Derechos Reservados */}
  <div className="footer-bottom">
    <p>© 2024 Todos los derechos reservados. 
      Desarrollado por <a href="https://bondgrowthpartner.com" target="_blank" rel="noopener noreferrer">Bond</a>
    </p>
  </div>
</footer>
     
    </div>
  );
}

export default Home;





