import React, { useState } from 'react';
import './Module.css';
import uncheckedIcon from '../assets/images/cheque.png';
import checkedIcon from '../assets/images/comprobado.png';

const Module = ({ moduleNumber, moduleTitle, lessons, onLessonSelect, currentLesson, progress }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="module">
      <div className="module-header" onClick={toggleExpand}>
        <h4 className="module-title">MÓDULO {moduleNumber} - {moduleTitle}</h4>
        <span className="expand-icon">{isExpanded ? '-' : '+'}</span>
      </div>
      {isExpanded && (
        <div className="lesson-list">
          {lessons.map((lesson, lessonIndex) => {
            const lessonKey = `${moduleNumber}-${lessonIndex}`;
            const completed = progress[lessonKey];
            const isCurrent = currentLesson.moduleIndex === moduleNumber - 1 && currentLesson.lessonIndex === lessonIndex;

            return (
              <div
                key={lessonIndex}
                className={`lesson ${isCurrent ? 'current-lesson' : ''}`}
                onClick={() => onLessonSelect({ moduleIndex: moduleNumber - 1, lessonIndex })}
              >
                <img
                  src="https://img.youtube.com/vi/dQw4w9WgXcQ/hqdefault.jpg"
                  alt={`Miniatura ${lesson}`}
                  className="video-thumbnail"
                />
                <span className="lesson-title">{lesson}</span>
                <img
                  src={completed ? checkedIcon : uncheckedIcon}
                  alt={completed ? "Completado" : "No completado"}
                  className="checkmark-icon"
                  style={{ width: '22px', height: '22px' }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Module;
