// CourseProgressContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../firebase/firebaseConfig';
import { useAuthContext } from './authContext';

const CourseProgressContext = createContext();

export const CourseProgressProvider = ({ children }) => {
  const { currentUser } = useAuthContext();
  const [progressData, setProgressData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchProgressData = async () => {
      if (currentUser) {
        const progressRef = doc(firestore, 'progress', currentUser.uid);
        const progressDoc = await getDoc(progressRef);

        if (progressDoc.exists()) {
          setProgressData(progressDoc.data());
          setIsDataLoaded(true);
        } else {
          console.warn("No progress data found.");
          setIsDataLoaded(true);
        }
      }
    };

    fetchProgressData();
  }, [currentUser]);

  return (
    <CourseProgressContext.Provider value={{ progressData, isDataLoaded }}>
      {children}
    </CourseProgressContext.Provider>
  );
};

export const useCourseProgress = () => useContext(CourseProgressContext);
